#experience {
    display: flex;
    flex-direction: column;
    margin: 100px 0;
}

#experience p {
    margin: 0;
}

.experience-item {
    display: flex;
    flex-direction: row;
    transition: 0.3s;
    padding: 25px;
    border-radius: 8px;
}

.experience-item:hover {
    background-color: var(--color6);
    box-shadow: 0px -1px 1px 0px var(--color4), 0px 2px 1px 0px var(--color6);
    cursor: pointer;
    
}

.experience-item:hover .experience-right h3 {
    color: var(--color5);
}

.experience-item:hover .experience-left p {
    color: var(--color1);
}

.experience-left {
    width: 30%;
}

.experience-right {
    width: 70%;
}

.experience-right h3 {
    margin: 0;
    margin-bottom: 20px;
    transition: 0.3s;
}

.experience-left p {
    font-size: 0.8em;
    font-weight: bold;
    align-self: center;
    justify-self: center;
}

.experience-skills {
    display: flex;
    margin-top: 20px;
}

.experience-skill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color4);
    border-radius: 15px;
    padding: 10px;
    margin-right: 5px;
    color: var(--color5);
}
