#projects {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.project {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 25px;
    border-radius: 8px;
    transition: 0.3s;
    margin-bottom: 30px;
}

.project:hover {
    background-color: var(--color6);
    box-shadow: 0px -1px 1px 0px var(--color4), 0px 2px 1px 0px var(--color6);
    cursor: pointer;
}

.project:hover h3 {
    color: var(--color5);
}

.project h3 {
    margin: 0;
}

.project-left {
    width: 30%;
}

.project-right {
    width: 70%;
}

.project-img-container {
    width: 70%;
    height: 70px;
    background-color: var(--color4);
    border-radius: 5px;
    overflow: hidden;
    border: 2px solid var(--color4);
}

.project-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-stats {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.project-stats p {
    margin-left: 10px;
    color: var(--color1);
}