:root {
  --color1: #e6e6fa;
  --color2: #14143e;
  --color3: #9e9eb3;
  --color4: #2c2c60;
  --color5: #7070ff;
  --color6: #1f1f52;

  --radius1: 5px;

  --shadow1: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color2);
  color: var(--color1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 1.3em;
  font-weight: normal;
}

p {
  line-height: 1.5;
  color: var(--color3);
}