#app-content {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    height: 100vh;
    padding: 5% 10%;
    align-items: end;
}

#content-left,
#content-right {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 100%;
}

#content-left {
    width: 40%;
    position: fixed;
    left: 10%;
}

#content-right {
    width: 50%;
}

#main-info img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: 0px -1px 1px 0px rgb(200, 198, 198), 0px 3px 3px 0px black;
    transition: 0.3s;
}

#main-info img:hover {
    box-shadow: 0px -1px 1px 0px rgb(200, 198, 198), 0px 10px 10px 0px black;
    transform: translateY(-10px);
}

#main-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

#main-info>* {
    margin: 10px 0;
}

#main-info p {
    width: 50%;
}

#img-container h1,
#img-container h2 {
    padding: 10px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#spotlight {
    position: fixed;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    pointer-events: none;
    mix-blend-mode: screen;
    /* Using 'screen' blend mode to brighten */
    background: radial-gradient(circle at center, rgba(93, 30, 156, 0.131) 0%, rgba(255, 255, 255, 0) 70%);
    transition: all 0.1s ease-out;
}

.category-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    min-height: 100px;
    width: 100%;
    text-align: end;
    justify-content: start;
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--color3);
}

.about-paragraph {
    white-space: pre-wrap;
    color: var(--color3);
    line-height: 1.6em;
}

.custom-link {
    color: white;
    background-color: transparent;
    text-decoration: none;
    transition: 0.2s;
}

.custom-link:hover {
    color: var(--color5);
    cursor: pointer;
}

@media only screen and (max-device-width: 480px) {
    #app-content {
        flex-direction: column;
        height: auto;
    }

    #content-left,
    #content-right {
        position: relative;
        width: 100%;
        left: 0;
        margin-top: 50px;
    }

    .left-menu {
        display: none !important;
    }

    .experience-item {
        flex-direction: column !important;
    }

    .project {
        flex-direction: column !important;
    }

    .project-left {
        width: 60% !important;
        margin-bottom: 30px;
    }
}